<template src='./template.html'>
  
</template>

<script>
export default {
    data(){
        return{
            open:false
        }
    },
    mounted(){
        this.imgStatusApi()
    },
    methods:{
        imgStatusApi(){
            this.axios.post('/api/index/get_insidebanner')
            .then(res => (
                this.open = res.data.isbanner
            ))
        }
    }
}
</script>

<style>

</style>