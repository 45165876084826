<template>
  <div class="shopmanual">
    <Pagenationtopimg/>
    <Pagenationtop :pagenation_title='pagenation_title' path='shopmanual'/>
    <div class="container">
        <div class="btn-container">
            <button
            v-for="(item,index) in main_data"
            :key="item.index"
            @click="goTo(index)">
                {{item.title}}
            </button>
        </div>
        <div class="sub-container"
        v-for="(item,index) in main_data"
        :key="item.index"
        :id="classIndex(index)">
            <div class="title">
                <h3>
                    {{ item.title }}
                </h3>
            </div>
            <div class="content-container">
                <div class="content"
                v-html="item.content">
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from '../components/pagenationtop/index.vue'
import qs from 'qs'
export default {
    data(){
        return{
            pagenation_title:[
                '購物說明'
            ],
            main_data:null,
        }
    },
    components:{
        Pagenationtopimg,
        Pagenationtop,
    },
    created(){
        this.$store.commit('openOverlay')
        this.getData()
    },
    mounted(){
    },
    methods:{
        goTo(index){
            document.getElementById(index).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
            });
        },
        classIndex(index){
            return `${index}`
        },
        getData(){
            this.axios.post('/api/directions/getdirections',qs.stringify({
                lang:this.$store.state.lang
            }))
            .then(response => (
                console.log(response),
                this.main_data = response.data.result,
                this.$store.commit('closeOverlay')
            ))
            .catch(error => (
                console.log(error)
            ))
        }
    }

}
</script>

