<template src='./template.html'>
  
</template>

<script>
export default {
    props:[
        'pagenation_title',
        'path',
        'subtitle',
        'shop'
    ],
    methods:{
        back(){
            window.history.go(-1)
        },
        router(fid,sid,iid){
            this.$router.push({name:`ShoppingCenter` , 
            params: {
                    fid: fid,
                    sid: sid, 
                    iid: iid
                } 
            })
        }
    }
}
</script>

<style>

</style>